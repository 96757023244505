.acceptance-buttons {
  margin-top: 20px;
}

.action-buttons {
  margin-bottom: 15px;
}

.action--archived {
  background-color: gainsboro;
}

.action__content {
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 576px) {
  .action__content__content-column,
  .action__content__content-column__column {
    padding-left: 0;
    padding-right: 0;
  }
}

.action__content p,
.action__description {
  color: var(--ion-color-dark);
  font-size: 16px;
  margin-bottom: 10px;
}

.action__key-info {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.action__key-info__container {
  margin: 0 0 20px;
}

.action__key-info__icon-container {
  align-items: center;
  color: var(--ion-color-primary);
  display: flex;
  flex-wrap: nowrap;
  font-family: 'Oswald', sans-serif;
  font-size: 32px;
  height: 40px;
}

.action__key-info__text {
  color: var(--ion-color-primary);
}

.hydrated.action__label--impact {
  min-width: 100px;
}

.action__image--archived {
  filter: contrast(70%) grayscale(70%);
}

.action__impact-points__label {
  font-size: 10px !important;
}

.action__modal__text,
.action__modal__text > p {
  text-align: center;
}

.action__tips {
  color: var(--ion-color-dark);
  margin: 20px 0 10px;
}

.action__tips__hint {
  align-items: center;
  color: var(--ion-color-medium);
  display: flex;
}

.action__tips__hint__icon {
  margin-right: 5px;
}

.action__title {
  font-size: 32px;
  margin-top: 20px;
}

.action__title h2 {
  text-align: left;
}

.actions__title__link,
.actions__title__link:link,
.actions__title__link:visited {
  text-decoration: none;
}

.action--archived .actions__title__link,
.action--archived .actions__title__link:link,
.action--archived .actions__title__link:visited {
  color: var(--ion-color-secondary-contrast);
}

.badge {
  margin-right: 10px;
  text-transform: uppercase;
}

.button--feedback,
.button--share {
  margin-top: 15px;
}

.button--skip {
  color: var(--ion-color-medium);
  cursor: pointer;
  font-size: 9px;
  font-weight: normal;
  text-transform: none;
}

.card {
  --ion-background-color: white;
}

.card--page {
  margin-left: 0;
  margin-right: 0;
}

.edit-button {
  margin-top: 10px;
}

.feedback__textarea {
  margin-bottom: 15px;
  text-align: left;
  width: 100%;
}

.in-progress {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

ion-icon.in-progress__icon {
  animation-name: spin-3d;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  font-size: 48px;
}

.in-progress__message {
  text-align: center;
}

.rich-text-editor {
  font-family: Arial, Helvetica, sans-serif;
}

.subtitle {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.subtitle__admin-warnings {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
}

.subtitle__badge__container {
  align-items: center;
  display: flex;
}

.subtitle__dates {
  margin-right: 10px;
}

.subtitle__info {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.update-buttons {
  margin-top: 20px;
}

@keyframes spin-3d {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(-360deg);
  }
}
