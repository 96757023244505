.launch__siren-player__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 20px 0 30px;
  width: 100%;
}

#root .launch__siren-player {
  background-color: var(--ion-color-primary);
  border-radius: var(--border-radius-normal);
}

.launch__siren-player .rhap_progress-filled,
.launch__siren-player .rhap_progress-indicator,
.launch__siren-player .rhap_volume-indicator {
  background-color: var(--ion-color-primary-contrast);
  opacity: 1;
}

.launch__siren-player .rhap_main-controls-button,
.launch__siren-player .rhap_repeat-button,
.launch__siren-player .rhap_time,
.launch__siren-player .rhap_volume-button {
  color: var(--ion-color-primary-contrast);
}

.launch__siren-player__image {
  max-width: 400px;
}

.launch__thumbnails {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.launch__thumbnails > img,
.launch__thumbnails > ion-img {
  height: auto;
  margin: 10px;
  max-width: 200px;
  width: 100%;
}

@media (min-width: 576px) {
  .launch__thumbnails > img,
  .launch__thumbnails > ion-img {
    width: 50%;
  }
}

.launch__video__container {
  padding-top: 56.25%;
  position: relative;
  width: 100%;
}

.launch__video__container iframe {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
