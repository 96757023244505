.about__article-link {
  cursor: pointer;
  --detail-icon-color: var(--ion-color-primary);
  --detail-icon-opacity: 1;
  max-width: 350px;
  --padding-start: 0;
}

.about__battles__heading {
  color: var(--ion-color-primary);
}
