.impact__first-name {
  text-transform: initial;
}

.impact__header {
  margin-bottom: 40px;
}

.impact__logout {
  margin: 30px 0;
}

.impact__profile {
  margin-top: 40px;
}

.impact__profile__item.item {
  --background: none;
}

.impact__profile__item ion-label {
  max-width: 90% !important;
}

.impact__total__inner {
  align-items: center;
  background-color: var(--ion-color-primary-contrast);
  border-radius: 50%;
  display: flex;
  height: 200px;
  justify-content: center;
  padding: 10px 10px 20px;
  width: 200px;
}

@media (min-width: 540px) {
  .impact__total {
    font-size: var(--font-size-very-large-numbers-lg) !important;
  }

  .impact__total__inner {
    height: 250px;
    width: 250px;
  }
}
