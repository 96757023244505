@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap);
.cookie-consent {
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-dark);
  position: absolute;
  width: 100%;
  z-index: 10000;
}

.cookie-consent__buttons {
  align-items: center;
  display: flex;
  justify-content: center;
}

.cookie-consent__text {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 5px 20px;
  text-align: center;
}

@media (min-width: 576px) {
  .cookie-consent__buttons {
    justify-content: flex-start;
  }

  .cookie-consent__text {
    justify-content: flex-end;
    padding: 5px 15px;
    text-align: left;
  }
}

h1,
h2,
h3,
h4 {
  font-family: 'Oswald', sans-serif;
  text-align: center;
  text-transform: uppercase;
}

h1 {
  font-size: 48px !important;
}

h2 {
  font-size: 32px !important;
}

h3 {
  font-size: 24px !important;
}

h4 {
  font-size: 18px !important;
  text-align: left;
}

hr {
  background-color: rgba(0, 0, 0, 0.2);
  margin: 30px auto;
  opacity: 0.5;
  width: 50%;
}

.important p {
  font-weight: bold;
  text-align: center;
}

ion-card {
  border-radius: var(--border-radius-normal);
}

@media (max-width: 359.98px) {
  .ion-hide-xxs-down {
    display: none !important;
  }
}

@media (min-width: 360px) {
  .ion-hide-xxs-up {
    display: none !important;
  }
}

ion-label.label--error {
  --color: var(--ion-color-primary) !important;
}

ion-item {
  --border-color: var(--ion-color-primary);
}

.link {
  color: var(--ion-color-primary);
  cursor: pointer;
  text-decoration: underline;
}

ol li {
  margin-bottom: 10px;
}

#root ion-item,
#root ion-list {
  --background: transparent;
}

.text--centred {
  text-align: center;
}

.text-small {
  font-size: var(--font-size-small);
}

ion-grid.content-column {
  --ion-grid-width: 100%;
  width: 100%;
}

@media (min-width: 1200px) {
  ion-grid.content-column ion-col {
    max-width: 700px !important;
  }
}

.about__article-link {
  cursor: pointer;
  --detail-icon-color: var(--ion-color-primary);
  --detail-icon-opacity: 1;
  max-width: 350px;
  --padding-start: 0;
}

.about__battles__heading {
  color: var(--ion-color-primary);
}

.content {
  --padding-bottom: 16px;
  --padding-end: 16px;
  --padding-start: 16px;
  --padding-top: -10px;
}

.content--full-width {
  --padding-end: 0;
  --padding-start: 0;
}

.content__grid,
.content__grid__col {
  padding-left: 0;
  padding-right: 0;
}

.footer-links {
  font-size: 12px;
  line-height: 20px;
  margin: 20px 0 10px;
  text-align: center;
}

.footer-links > *:not(:last-child) {
  margin-right: 10px;
}

.logo {
  height: 90px;
  margin-bottom: 10px;
  width: auto;
}

.page {
  --ion-background-color: var(--background-colour) url(/static/media/background-paper-800.d462f3d7.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-height: 801px), (min-width: 801px) {
  .page {
    --ion-background-color: var(--background-colour) url(/static/media/background-paper-1024.53911acd.jpg);
  }
}

@media (min-height: 1025px), (min-width: 1025px) {
  .page {
    --ion-background-color: var(--background-colour) url(/static/media/background-paper-1600.812be854.jpg);
  }
}

@media (min-height: 1601px), (min-width: 1601px) {
  .page {
    --ion-background-color: var(--background-colour) url(/static/media/background-paper-2000.94e94360.jpg);
  }
}

@media (min-height: 2001px), (min-width: 2001px) {
  .page {
    --ion-background-color: var(--background-colour) url(/static/media/background-paper-2200.818d0ce3.jpg);
  }
}

.title {
  border-bottom: solid 5px var(--ion-color-primary);
  color: var(--ion-color-primary);
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
}

.toolbar {
  --background: transparent;
  --border-color: transparent;
  --min-height: 100px;
  display: flex;
  justify-content: center;
}

.toolbar--scrolled {
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.8);
  transition: background-color 2s;
}

.close-button {
  cursor: pointer;
}

.close-button__container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

ion-modal.modal,
ion-modal.modal:first-of-type {
  --backdrop-opacity: 0.5;
}

.modal .modal-wrapper {
  --background: var(--ion-color-primary);
  border-radius: 10px;
  color: var(--ion-color-primary-contrast);
  --height: 20vh;
  --max-width: 600px;
  --min-height: 350px;
  --width: 90vw;
}

.modal .modal-wrapper a,
.modal .modal-wrapper a:link,
.modal .modal-wrapper a:visited,
.modal .modal-wrapper a:hover,
.modal .modal-wrapper a:active,
.modal .modal-wrapper a:focus,
.modal .modal-wrapper ion-item.item-has-focus ion-label,
.modal .modal-wrapper ion-select {
  color: var(--ion-color-primary-contrast) !important;
}

.modal .modal-wrapper .ion-color-danger {
  --ion-color-base: var(--ion-color-primary-contrast) !important;
}

.modal .modal-wrapper ion-input,
.modal .modal-wrapper ion-label,
.modal .modal-wrapper .button-clear {
  --color: var(--ion-color-primary-contrast) !important;
}

.modal .modal-wrapper ion-item,
.modal .modal-wrapper ion-item.item-has-focus {
  --border-color: var(--ion-color-primary-contrast);
}

.modal .modal-wrapper ion-item.item-has-focus {
  --highlight-background: var(--ion-color-primary-contrast);
}

.modal--acceptance .modal-wrapper {
  --min-height: 375px;
}

.modal--acceptance-in-progress .modal-wrapper {
  --min-height: 200px;
}

.modal--auth .modal-wrapper {
  --min-height: 675px;
}

.modal--completion .modal-wrapper {
  --min-height: 450px;
}

.modal__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal__inner__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.alerts__co2__container {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary-contrast);
  margin: 10px 0;
  padding: 10px;
  text-align: center;
  width: 100%;
}

@media (min-width: 540px) {
  .alerts__co2__container {
    margin: 10px auto;
    width: 90%;
  }
}

.alerts__co2__details {
  font-size: 10px;
  text-align: center;
  width: 100%;
}

.alerts__co2__details a,
.alerts__co2__details a:link,
.alerts__co2__details a:visited,
.alerts__co2__details a:hover,
.alerts__co2__details a:active,
.alerts__co2__details a:focus {
  color: var(--ion-color-primary-contrast);
}

.alerts__co2__explainer {
  margin-top: 0;
  text-transform: uppercase;
}

.alerts__co2__headline {
  margin-bottom: 5px;
  margin-top: 0;
  text-align: center;
}

h2.alerts__co2__headline {
  font-size: var(--font-size-very-large-numbers-sm) !important;
}
@media (min-width: 540px) {
  h2.alerts__co2__headline {
    font-size: var(--font-size-very-large-numbers-lg) !important;
  }
}

.alerts__co2__intro {
  padding: 0;
}

.alerts__icon--co2-difference {
  font-size: 20px;
}

.alerts__icon--info {
  cursor: pointer;
  font-size: 14px;
}

.alerts__news__col,
.alerts__news__grid,
.alerts__news__row {
  padding: 0;
}

.alerts__news__item.item {
  --background: var(--ion-color-primary) !important;
  --background-focused: var(--ion-color-primary-tint) !important;
  --background-hover: var(--ion-color-primary-tint) !important;
  border-radius: var(--border-radius-normal);
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  cursor: pointer;
  --detail-icon-color: var(--ion-color-primary-contrast);
  --detail-icon-opacity: 1;
  font-size: 14px;
  margin: 10px 0;
}

.alerts__news__item--edit {
  border-bottom: solid 1px var(--ion-color-primary);
  padding-bottom: 25px;
}

.alerts__news__item--edit__future {
  font-size: 12px;
}

.alerts__news__item__date {
  color: var(--ion-color-light);
  font-family: inherit;
  font-family: var(--ion-font-family, inherit);
  font-size: 12px;
  font-weight: normal;
  margin-top: 5px;
  text-transform: none;
}

.item ion-label.alerts__news__item__headline {
  font-family: 'Oswald', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
}
@media (min-width: 540px) {
  .item ion-label.alerts__news__item__headline {
    font-size: 20px;
  }
}

.alerts__siren {
  max-width: 300px;
  width: 80%;
}

.alerts__siren__container {
  display: flex;
  justify-content: center;
  margin: -20px 0 0;
}

.loading {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 30px 0;
}

.loading__spinner {
  margin-right: 10px;
}

.card-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 55%;
  position: relative;
  width: 100%;
}

@media (min-width: 1200px) {
  .card-image--hq {
    padding-top: 111%;
  }
}

.card-image__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.card-image__credit {
  color: var(--ion-color-medium);
  font-size: 10px;
  opacity: 0.8;
  padding: 0 3px 3px 0;
  text-align: right;
  width: 100%;
}

.card-image__header {
  background: rgb(67, 67, 67);
  background: linear-gradient(180deg, rgba(67, 67, 67, 1) 0%, rgba(67, 67, 67, 0) 100%);
  margin: 0;
  padding: 10px 20px 30px;
  text-align: left;
  width: 100%;
}

@media (max-width: 359.98px) {
  .card-image__header {
    font-size: 25px !important;
  }
}

.card-image__header,
.card-image__header:link,
.card-image__header:visited,
.card-image__header:hover,
.card-image__header:link,
.card-image__header:active {
  color: white !important;
  text-decoration: none;
}

.acceptance-buttons {
  margin-top: 20px;
}

.action-buttons {
  margin-bottom: 15px;
}

.action--archived {
  background-color: gainsboro;
}

.action__content {
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 576px) {
  .action__content__content-column,
  .action__content__content-column__column {
    padding-left: 0;
    padding-right: 0;
  }
}

.action__content p,
.action__description {
  color: var(--ion-color-dark);
  font-size: 16px;
  margin-bottom: 10px;
}

.action__key-info {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.action__key-info__container {
  margin: 0 0 20px;
}

.action__key-info__icon-container {
  align-items: center;
  color: var(--ion-color-primary);
  display: flex;
  flex-wrap: nowrap;
  font-family: 'Oswald', sans-serif;
  font-size: 32px;
  height: 40px;
}

.action__key-info__text {
  color: var(--ion-color-primary);
}

.hydrated.action__label--impact {
  min-width: 100px;
}

.action__image--archived {
  -webkit-filter: contrast(70%) grayscale(70%);
          filter: contrast(70%) grayscale(70%);
}

.action__impact-points__label {
  font-size: 10px !important;
}

.action__modal__text,
.action__modal__text > p {
  text-align: center;
}

.action__tips {
  color: var(--ion-color-dark);
  margin: 20px 0 10px;
}

.action__tips__hint {
  align-items: center;
  color: var(--ion-color-medium);
  display: flex;
}

.action__tips__hint__icon {
  margin-right: 5px;
}

.action__title {
  font-size: 32px;
  margin-top: 20px;
}

.action__title h2 {
  text-align: left;
}

.actions__title__link,
.actions__title__link:link,
.actions__title__link:visited {
  text-decoration: none;
}

.action--archived .actions__title__link,
.action--archived .actions__title__link:link,
.action--archived .actions__title__link:visited {
  color: var(--ion-color-secondary-contrast);
}

.badge {
  margin-right: 10px;
  text-transform: uppercase;
}

.button--feedback,
.button--share {
  margin-top: 15px;
}

.button--skip {
  color: var(--ion-color-medium);
  cursor: pointer;
  font-size: 9px;
  font-weight: normal;
  text-transform: none;
}

.card {
  --ion-background-color: white;
}

.card--page {
  margin-left: 0;
  margin-right: 0;
}

.edit-button {
  margin-top: 10px;
}

.feedback__textarea {
  margin-bottom: 15px;
  text-align: left;
  width: 100%;
}

.in-progress {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

ion-icon.in-progress__icon {
  -webkit-animation-name: spin-3d;
          animation-name: spin-3d;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  font-size: 48px;
}

.in-progress__message {
  text-align: center;
}

.rich-text-editor {
  font-family: Arial, Helvetica, sans-serif;
}

.subtitle {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.subtitle__admin-warnings {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
}

.subtitle__badge__container {
  align-items: center;
  display: flex;
}

.subtitle__dates {
  margin-right: 10px;
}

.subtitle__info {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.update-buttons {
  margin-top: 20px;
}

@-webkit-keyframes spin-3d {
  from {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(-360deg);
            transform: rotateY(-360deg);
  }
}

@keyframes spin-3d {
  from {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(-360deg);
            transform: rotateY(-360deg);
  }
}

.share__web {
  display: flex;
  justify-content: center;
  width: 100%;
}

.share__item {
  margin-bottom: 10px;
  margin-right: 10px;
}

.enlist__already {
  font-size: 12px;
  margin: 20px 0 0;
  text-align: center;
}

.enlist__begin__blurb {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.enlist__begin__button--submit {
  margin: 20px 0 10px;
}

.enlist__begin__header {
  font-size: 80px !important;
  margin-top: 0;
}

@media (min-width: 768px) {
  .enlist__begin__header {
    font-size: 61px !important;
  }
}

@media (min-width: 992px) {
  .enlist__begin__header {
    font-size: 72px !important;
  }
}

.enlist__begin__last-name {
  margin-bottom: 15px;
}

.enlist__begin__sign-up-encourage {
  padding-left: 16px;
}

.actions__list__item.item {
  --background: none;
}

.actions__list {
  background: transparent !important;
}

@media (min-width: 540px) {
  .actions__list__item {
    --padding-bottom: 20px;
  }
}

.actions__list__item > div {
  width: 100%;
}

.boris__buttons {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.boris__video__container {
  padding-top: 56.25%;
  position: relative;
  width: 100%;
}

.boris__video__container iframe {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.intro-blurb h2 {
  font-size: 40px !important;
  margin-top: 10px;
}

.intro-blurb h3 {
  font-size: 27px !important;
}

.intro-blurb p {
  font-size: 24px !important;
}

.intro-blurb__buttons {
  display: flex;
  flex-direction: column;
}

.enlist__siren {
  margin: 0 20px;
  max-width: 500px;
}

.enlist__siren__container {
  display: flex;
  justify-content: center;
}

.auth-provider-button {
  margin-bottom: 20px;
}

.or-divider {
  margin: 20px 0 -10px;
}

.or-divider__line {
  border-top: solid 1px var(--ion-color-dark);
}

.or-divider__line__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.or-divider__text {
  text-align: center;
}

.enlist__register__blurb {
  text-align: center;
}

.enlist__register__button--submit {
  margin-top: 20px;
}

.enlist__success__item {
  --border-radius: var(--border-radius-normal);
  --detail-icon-opacity: 1;
  font-weight: bold;
  margin-bottom: 5px;
}

.impact-score {
  align-items: center;
  background-color: var(--ion-color-primary);
  border-radius: var(--border-radius-normal);
  color: var(--ion-color-primary-contrast);
  display: flex;
  flex-direction: column;
}

.impact-score--body {
  margin: 10px;
  padding: 15px;
}

@media (min-width: 768px) {
  .impact-score--body {
    padding: 25px 15px;
  }
}

.impact-score--sidebar {
  padding: 0 10px 10px;
}

.impact-score__caption {
  font-size: var(--font-size-small);
  font-weight: bold;
  text-transform: uppercase;
}

.impact-score__score {
  align-items: center;
  display: flex;
  font-family: 'Oswald', sans-serif;
  font-size: var(--font-size-very-large-numbers-sm);
  justify-content: center;
  width: 100%;
}

ion-icon.impact-score__score__icon {
  height: 52px;
  margin-top: 5px;
  width: 52px;
}

.hq ion-card ion-grid {
  padding: 0;
}

.hq__actions__action {
  --background: var(--ion-color-primary);
  height: 100%;
  margin-bottom: 0;
  width: 100%;
}

ion-card.hq__actions__action__card {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0;
  width: 100%;
}

.hq__actions__action__slides {
  --bullet-background: var(--ion-color-light);
  --bullet-background-active: var(--ion-color-primary);
  width: 100%;
}

.hq__actions__action__slides__container {
  width: 100%;
}

.hq__actions__action__slides__container > * {
  height: 100%;
}

.hq__actions__action__slides__slide {
  width: 100%;
}

.hq__actions__col {
  --ion-grid-column-padding: 10px;
}

@media (min-width: 1200px) {
  .hq__actions__col {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.hq__alerts.hq__card {
  --background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0;
  width: 100%;
}

.hq__alerts__news {
  display: flex;
  font-family: 'Oswald', sans-serif;
  font-size: 14px;
  padding: 10px 5px;
  text-transform: uppercase;
}

@media (min-width: 540px) {
  .hq__alerts__news {
    font-size: 18px;
  }
}

.hq__alerts__news__beginning-text {
  font-weight: bold;
  margin-right: 5px;
}

.hq__card {
  margin-bottom: 20px;
  margin-left: 0;
  margin-top: 0;
}

.hq__card__button {
  margin: 0;
}

.hq__card__divider {
  border-bottom: solid 1px var(--background-colour);
}

@media (min-width: 720px) {
  .hq__enlist__col,
  .hq__impact__col {
    padding-left: 20px;
  }
}

.hq__enlist__siren {
  max-width: 300px;
}

.hq__enlist__siren__container {
  display: flex;
  justify-content: center;
}

.hq__impact {
  --background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.hq__impact-alerts__col {
  display: flex;
  flex-direction: column;
  --ion-grid-column-padding: 10px;
  justify-content: space-between;
}

@media (min-width: 1200px) {
  .hq__impact-alerts__col {
    padding-bottom: 0 !important;
  }

  .hq__impact-alerts__col > * {
    height: 50%;
  }
}

@media (min-width: 720px) {
  .hq__impact__content {
    padding-bottom: 15px;
  }
}

.hq__total {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.hq__total__figure {
  align-items: center;
  display: flex;
  font-family: 'Oswald', sans-serif;
  font-size: var(--font-size-very-large-numbers-sm);
  justify-content: center;
}

.hq__total__figure__icon--co2-difference {
  height: 36px;
  margin-top: 5px;
  width: 36px;
}

.hq__total__icon {
  height: 32px;
  width: 32px;
}

.hq__total__text {
  font-size: var(--font-size-small);
  font-weight: bold;
  text-transform: uppercase;
}

ion-col {
  --ion-grid-column-padding-sm: 0;
}

.impact__first-name {
  text-transform: none;
  text-transform: initial;
}

.impact__header {
  margin-bottom: 40px;
}

.impact__logout {
  margin: 30px 0;
}

.impact__profile {
  margin-top: 40px;
}

.impact__profile__item.item {
  --background: none;
}

.impact__profile__item ion-label {
  max-width: 90% !important;
}

.impact__total__inner {
  align-items: center;
  background-color: var(--ion-color-primary-contrast);
  border-radius: 50%;
  display: flex;
  height: 200px;
  justify-content: center;
  padding: 10px 10px 20px;
  width: 200px;
}

@media (min-width: 540px) {
  .impact__total {
    font-size: var(--font-size-very-large-numbers-lg) !important;
  }

  .impact__total__inner {
    height: 250px;
    width: 250px;
  }
}

.launch__siren-player__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 20px 0 30px;
  width: 100%;
}

#root .launch__siren-player {
  background-color: var(--ion-color-primary);
  border-radius: var(--border-radius-normal);
}

.launch__siren-player .rhap_progress-filled,
.launch__siren-player .rhap_progress-indicator,
.launch__siren-player .rhap_volume-indicator {
  background-color: var(--ion-color-primary-contrast);
  opacity: 1;
}

.launch__siren-player .rhap_main-controls-button,
.launch__siren-player .rhap_repeat-button,
.launch__siren-player .rhap_time,
.launch__siren-player .rhap_volume-button {
  color: var(--ion-color-primary-contrast);
}

.launch__siren-player__image {
  max-width: 400px;
}

.launch__thumbnails {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.launch__thumbnails > img,
.launch__thumbnails > ion-img {
  height: auto;
  margin: 10px;
  max-width: 200px;
  width: 100%;
}

@media (min-width: 576px) {
  .launch__thumbnails > img,
  .launch__thumbnails > ion-img {
    width: 50%;
  }
}

.launch__video__container {
  padding-top: 56.25%;
  position: relative;
  width: 100%;
}

.launch__video__container iframe {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.login__button--submit {
  margin: 20px 0 10px;
}

:root {
  --ion-color-primary: #e00000;
  --ion-color-primary-rgb: 224, 0, 0;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #c50000;
  --ion-color-primary-tint: #e31a1a;

  --ion-color-secondary: #ffffff;
  --ion-color-secondary-semi-transparent: rgba(255, 255, 255, 0.4);
  --ion-color-secondary-rgb: 255, 255, 255;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e0e0e0;
  --ion-color-secondary-tint: #ffffff;

  --ion-color-tertiary: #fff5f5;
  --ion-color-tertiary-rgb: 255, 245, 245;
  --ion-color-tertiary-contrast: #c80000;
  --ion-color-tertiary-contrast-rgb: 200, 0, 0;
  --ion-color-tertiary-shade: #e0d8d8;
  --ion-color-tertiary-tint: #fff6f6;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --background-colour: #f9d4a8;

  --border-radius-normal: 10px;

  --font-size-small: 12px;
  --font-size-very-large-numbers-sm: 48px;
  --font-size-very-large-numbers-lg: 74px;

  --screen-width-sm: 540px;
}

.ion-color-facebook {
  --ion-color-base: #4267b2;
  --ion-color-base-rgb: 85, 118, 186;
  --ion-color-contrast: #fff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #3a5b9d;
  --ion-color-tint: #5576ba;
}

.ion-color-google {
  --ion-color-base: #4285f4;
  --ion-color-base-rgb: 66, 133, 244;
  --ion-color-contrast: #fff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #3a75d7;
  --ion-color-tint: #5591f5;
}

