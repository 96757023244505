.impact-score {
  align-items: center;
  background-color: var(--ion-color-primary);
  border-radius: var(--border-radius-normal);
  color: var(--ion-color-primary-contrast);
  display: flex;
  flex-direction: column;
}

.impact-score--body {
  margin: 10px;
  padding: 15px;
}

@media (min-width: 768px) {
  .impact-score--body {
    padding: 25px 15px;
  }
}

.impact-score--sidebar {
  padding: 0 10px 10px;
}

.impact-score__caption {
  font-size: var(--font-size-small);
  font-weight: bold;
  text-transform: uppercase;
}

.impact-score__score {
  align-items: center;
  display: flex;
  font-family: 'Oswald', sans-serif;
  font-size: var(--font-size-very-large-numbers-sm);
  justify-content: center;
  width: 100%;
}

ion-icon.impact-score__score__icon {
  height: 52px;
  margin-top: 5px;
  width: 52px;
}
