:root {
  --ion-color-primary: #e00000;
  --ion-color-primary-rgb: 224, 0, 0;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #c50000;
  --ion-color-primary-tint: #e31a1a;

  --ion-color-secondary: #ffffff;
  --ion-color-secondary-semi-transparent: rgba(255, 255, 255, 0.4);
  --ion-color-secondary-rgb: 255, 255, 255;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e0e0e0;
  --ion-color-secondary-tint: #ffffff;

  --ion-color-tertiary: #fff5f5;
  --ion-color-tertiary-rgb: 255, 245, 245;
  --ion-color-tertiary-contrast: #c80000;
  --ion-color-tertiary-contrast-rgb: 200, 0, 0;
  --ion-color-tertiary-shade: #e0d8d8;
  --ion-color-tertiary-tint: #fff6f6;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --background-colour: #f9d4a8;

  --border-radius-normal: 10px;

  --font-size-small: 12px;
  --font-size-very-large-numbers-sm: 48px;
  --font-size-very-large-numbers-lg: 74px;

  --screen-width-sm: 540px;
}

.ion-color-facebook {
  --ion-color-base: #4267b2;
  --ion-color-base-rgb: 85, 118, 186;
  --ion-color-contrast: #fff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #3a5b9d;
  --ion-color-tint: #5576ba;
}

.ion-color-google {
  --ion-color-base: #4285f4;
  --ion-color-base-rgb: 66, 133, 244;
  --ion-color-contrast: #fff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #3a75d7;
  --ion-color-tint: #5591f5;
}
