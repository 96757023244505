.enlist__already {
  font-size: 12px;
  margin: 20px 0 0;
  text-align: center;
}

.enlist__begin__blurb {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.enlist__begin__button--submit {
  margin: 20px 0 10px;
}

.enlist__begin__header {
  font-size: 80px !important;
  margin-top: 0;
}

@media (min-width: 768px) {
  .enlist__begin__header {
    font-size: 61px !important;
  }
}

@media (min-width: 992px) {
  .enlist__begin__header {
    font-size: 72px !important;
  }
}

.enlist__begin__last-name {
  margin-bottom: 15px;
}

.enlist__begin__sign-up-encourage {
  padding-left: 16px;
}
