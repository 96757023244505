.actions__list__item.item {
  --background: none;
}

.actions__list {
  background: transparent !important;
}

@media (min-width: 540px) {
  .actions__list__item {
    --padding-bottom: 20px;
  }
}

.actions__list__item > div {
  width: 100%;
}
