ion-grid.content-column {
  --ion-grid-width: 100%;
  width: 100%;
}

@media (min-width: 1200px) {
  ion-grid.content-column ion-col {
    max-width: 700px !important;
  }
}
