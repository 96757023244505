@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap');

h1,
h2,
h3,
h4 {
  font-family: 'Oswald', sans-serif;
  text-align: center;
  text-transform: uppercase;
}

h1 {
  font-size: 48px !important;
}

h2 {
  font-size: 32px !important;
}

h3 {
  font-size: 24px !important;
}

h4 {
  font-size: 18px !important;
  text-align: left;
}

hr {
  background-color: rgba(0, 0, 0, 0.2);
  margin: 30px auto;
  opacity: 0.5;
  width: 50%;
}

.important p {
  font-weight: bold;
  text-align: center;
}

ion-card {
  border-radius: var(--border-radius-normal);
}

@media (max-width: 359.98px) {
  .ion-hide-xxs-down {
    display: none !important;
  }
}

@media (min-width: 360px) {
  .ion-hide-xxs-up {
    display: none !important;
  }
}

ion-label.label--error {
  --color: var(--ion-color-primary) !important;
}

ion-item {
  --border-color: var(--ion-color-primary);
}

.link {
  color: var(--ion-color-primary);
  cursor: pointer;
  text-decoration: underline;
}

ol li {
  margin-bottom: 10px;
}

#root ion-item,
#root ion-list {
  --background: transparent;
}

.text--centred {
  text-align: center;
}

.text-small {
  font-size: var(--font-size-small);
}
