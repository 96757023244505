.intro-blurb h2 {
  font-size: 40px !important;
  margin-top: 10px;
}

.intro-blurb h3 {
  font-size: 27px !important;
}

.intro-blurb p {
  font-size: 24px !important;
}

.intro-blurb__buttons {
  display: flex;
  flex-direction: column;
}
