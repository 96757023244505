.loading {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 30px 0;
}

.loading__spinner {
  margin-right: 10px;
}
