.content {
  --padding-bottom: 16px;
  --padding-end: 16px;
  --padding-start: 16px;
  --padding-top: -10px;
}

.content--full-width {
  --padding-end: 0;
  --padding-start: 0;
}

.content__grid,
.content__grid__col {
  padding-left: 0;
  padding-right: 0;
}

.footer-links {
  font-size: 12px;
  line-height: 20px;
  margin: 20px 0 10px;
  text-align: center;
}

.footer-links > *:not(:last-child) {
  margin-right: 10px;
}

.logo {
  height: 90px;
  margin-bottom: 10px;
  width: auto;
}

.page {
  --ion-background-color: var(--background-colour) url(../../images/background-paper-800.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-height: 801px), (min-width: 801px) {
  .page {
    --ion-background-color: var(--background-colour) url(../../images/background-paper-1024.jpg);
  }
}

@media (min-height: 1025px), (min-width: 1025px) {
  .page {
    --ion-background-color: var(--background-colour) url(../../images/background-paper-1600.jpg);
  }
}

@media (min-height: 1601px), (min-width: 1601px) {
  .page {
    --ion-background-color: var(--background-colour) url(../../images/background-paper-2000.jpg);
  }
}

@media (min-height: 2001px), (min-width: 2001px) {
  .page {
    --ion-background-color: var(--background-colour) url(../../images/background-paper-2200.jpg);
  }
}

.title {
  border-bottom: solid 5px var(--ion-color-primary);
  color: var(--ion-color-primary);
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
}

.toolbar {
  --background: transparent;
  --border-color: transparent;
  --min-height: 100px;
  display: flex;
  justify-content: center;
}

.toolbar--scrolled {
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.8);
  transition: background-color 2s;
}
