.boris__buttons {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.boris__video__container {
  padding-top: 56.25%;
  position: relative;
  width: 100%;
}

.boris__video__container iframe {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
