.card-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 55%;
  position: relative;
  width: 100%;
}

@media (min-width: 1200px) {
  .card-image--hq {
    padding-top: 111%;
  }
}

.card-image__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.card-image__credit {
  color: var(--ion-color-medium);
  font-size: 10px;
  opacity: 0.8;
  padding: 0 3px 3px 0;
  text-align: right;
  width: 100%;
}

.card-image__header {
  background: rgb(67, 67, 67);
  background: linear-gradient(180deg, rgba(67, 67, 67, 1) 0%, rgba(67, 67, 67, 0) 100%);
  margin: 0;
  padding: 10px 20px 30px;
  text-align: left;
  width: 100%;
}

@media (max-width: 359.98px) {
  .card-image__header {
    font-size: 25px !important;
  }
}

.card-image__header,
.card-image__header:link,
.card-image__header:visited,
.card-image__header:hover,
.card-image__header:link,
.card-image__header:active {
  color: white !important;
  text-decoration: none;
}
