.or-divider {
  margin: 20px 0 -10px;
}

.or-divider__line {
  border-top: solid 1px var(--ion-color-dark);
}

.or-divider__line__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.or-divider__text {
  text-align: center;
}
