.cookie-consent {
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-dark);
  position: absolute;
  width: 100%;
  z-index: 10000;
}

.cookie-consent__buttons {
  align-items: center;
  display: flex;
  justify-content: center;
}

.cookie-consent__text {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 5px 20px;
  text-align: center;
}

@media (min-width: 576px) {
  .cookie-consent__buttons {
    justify-content: flex-start;
  }

  .cookie-consent__text {
    justify-content: flex-end;
    padding: 5px 15px;
    text-align: left;
  }
}
