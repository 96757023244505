.close-button {
  cursor: pointer;
}

.close-button__container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

ion-modal.modal,
ion-modal.modal:first-of-type {
  --backdrop-opacity: 0.5;
}

.modal .modal-wrapper {
  --background: var(--ion-color-primary);
  border-radius: 10px;
  color: var(--ion-color-primary-contrast);
  --height: 20vh;
  --max-width: 600px;
  --min-height: 350px;
  --width: 90vw;
}

.modal .modal-wrapper a,
.modal .modal-wrapper a:link,
.modal .modal-wrapper a:visited,
.modal .modal-wrapper a:hover,
.modal .modal-wrapper a:active,
.modal .modal-wrapper a:focus,
.modal .modal-wrapper ion-item.item-has-focus ion-label,
.modal .modal-wrapper ion-select {
  color: var(--ion-color-primary-contrast) !important;
}

.modal .modal-wrapper .ion-color-danger {
  --ion-color-base: var(--ion-color-primary-contrast) !important;
}

.modal .modal-wrapper ion-input,
.modal .modal-wrapper ion-label,
.modal .modal-wrapper .button-clear {
  --color: var(--ion-color-primary-contrast) !important;
}

.modal .modal-wrapper ion-item,
.modal .modal-wrapper ion-item.item-has-focus {
  --border-color: var(--ion-color-primary-contrast);
}

.modal .modal-wrapper ion-item.item-has-focus {
  --highlight-background: var(--ion-color-primary-contrast);
}

.modal--acceptance .modal-wrapper {
  --min-height: 375px;
}

.modal--acceptance-in-progress .modal-wrapper {
  --min-height: 200px;
}

.modal--auth .modal-wrapper {
  --min-height: 675px;
}

.modal--completion .modal-wrapper {
  --min-height: 450px;
}

.modal__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal__inner__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
