.share__web {
  display: flex;
  justify-content: center;
  width: 100%;
}

.share__item {
  margin-bottom: 10px;
  margin-right: 10px;
}
