.hq ion-card ion-grid {
  padding: 0;
}

.hq__actions__action {
  --background: var(--ion-color-primary);
  height: 100%;
  margin-bottom: 0;
  width: 100%;
}

ion-card.hq__actions__action__card {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0;
  width: 100%;
}

.hq__actions__action__slides {
  --bullet-background: var(--ion-color-light);
  --bullet-background-active: var(--ion-color-primary);
  width: 100%;
}

.hq__actions__action__slides__container {
  width: 100%;
}

.hq__actions__action__slides__container > * {
  height: 100%;
}

.hq__actions__action__slides__slide {
  width: 100%;
}

.hq__actions__col {
  --ion-grid-column-padding: 10px;
}

@media (min-width: 1200px) {
  .hq__actions__col {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.hq__alerts.hq__card {
  --background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0;
  width: 100%;
}

.hq__alerts__news {
  display: flex;
  font-family: 'Oswald', sans-serif;
  font-size: 14px;
  padding: 10px 5px;
  text-transform: uppercase;
}

@media (min-width: 540px) {
  .hq__alerts__news {
    font-size: 18px;
  }
}

.hq__alerts__news__beginning-text {
  font-weight: bold;
  margin-right: 5px;
}

.hq__card {
  margin-bottom: 20px;
  margin-left: 0;
  margin-top: 0;
}

.hq__card__button {
  margin: 0;
}

.hq__card__divider {
  border-bottom: solid 1px var(--background-colour);
}

@media (min-width: 720px) {
  .hq__enlist__col,
  .hq__impact__col {
    padding-left: 20px;
  }
}

.hq__enlist__siren {
  max-width: 300px;
}

.hq__enlist__siren__container {
  display: flex;
  justify-content: center;
}

.hq__impact {
  --background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.hq__impact-alerts__col {
  display: flex;
  flex-direction: column;
  --ion-grid-column-padding: 10px;
  justify-content: space-between;
}

@media (min-width: 1200px) {
  .hq__impact-alerts__col {
    padding-bottom: 0 !important;
  }

  .hq__impact-alerts__col > * {
    height: 50%;
  }
}

@media (min-width: 720px) {
  .hq__impact__content {
    padding-bottom: 15px;
  }
}

.hq__total {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.hq__total__figure {
  align-items: center;
  display: flex;
  font-family: 'Oswald', sans-serif;
  font-size: var(--font-size-very-large-numbers-sm);
  justify-content: center;
}

.hq__total__figure__icon--co2-difference {
  height: 36px;
  margin-top: 5px;
  width: 36px;
}

.hq__total__icon {
  height: 32px;
  width: 32px;
}

.hq__total__text {
  font-size: var(--font-size-small);
  font-weight: bold;
  text-transform: uppercase;
}

ion-col {
  --ion-grid-column-padding-sm: 0;
}
