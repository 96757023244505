.alerts__co2__container {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary-contrast);
  margin: 10px 0;
  padding: 10px;
  text-align: center;
  width: 100%;
}

@media (min-width: 540px) {
  .alerts__co2__container {
    margin: 10px auto;
    width: 90%;
  }
}

.alerts__co2__details {
  font-size: 10px;
  text-align: center;
  width: 100%;
}

.alerts__co2__details a,
.alerts__co2__details a:link,
.alerts__co2__details a:visited,
.alerts__co2__details a:hover,
.alerts__co2__details a:active,
.alerts__co2__details a:focus {
  color: var(--ion-color-primary-contrast);
}

.alerts__co2__explainer {
  margin-top: 0;
  text-transform: uppercase;
}

.alerts__co2__headline {
  margin-bottom: 5px;
  margin-top: 0;
  text-align: center;
}

h2.alerts__co2__headline {
  font-size: var(--font-size-very-large-numbers-sm) !important;
}
@media (min-width: 540px) {
  h2.alerts__co2__headline {
    font-size: var(--font-size-very-large-numbers-lg) !important;
  }
}

.alerts__co2__intro {
  padding: 0;
}

.alerts__icon--co2-difference {
  font-size: 20px;
}

.alerts__icon--info {
  cursor: pointer;
  font-size: 14px;
}

.alerts__news__col,
.alerts__news__grid,
.alerts__news__row {
  padding: 0;
}

.alerts__news__item.item {
  --background: var(--ion-color-primary) !important;
  --background-focused: var(--ion-color-primary-tint) !important;
  --background-hover: var(--ion-color-primary-tint) !important;
  border-radius: var(--border-radius-normal);
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  cursor: pointer;
  --detail-icon-color: var(--ion-color-primary-contrast);
  --detail-icon-opacity: 1;
  font-size: 14px;
  margin: 10px 0;
}

.alerts__news__item--edit {
  border-bottom: solid 1px var(--ion-color-primary);
  padding-bottom: 25px;
}

.alerts__news__item--edit__future {
  font-size: 12px;
}

.alerts__news__item__date {
  color: var(--ion-color-light);
  font-family: var(--ion-font-family, inherit);
  font-size: 12px;
  font-weight: normal;
  margin-top: 5px;
  text-transform: none;
}

.item ion-label.alerts__news__item__headline {
  font-family: 'Oswald', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
}
@media (min-width: 540px) {
  .item ion-label.alerts__news__item__headline {
    font-size: 20px;
  }
}

.alerts__siren {
  max-width: 300px;
  width: 80%;
}

.alerts__siren__container {
  display: flex;
  justify-content: center;
  margin: -20px 0 0;
}
